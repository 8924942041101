.map-container{
    width: 100%;
    height: 100%;
    border: 3px;
}

.places-container {
    position: absolute;
    top: 8rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
    padding-top: 10px;
  }

.current-location-container{
    position: absolute;
    top: 65%;
    left: 85%;
    transform: translateX(-50%);
    z-index: 10;
    width: 5%;
    padding-top: 40px;
    cursor: pointer;
  }

  .custom-map-control-button {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  
.combobox-input {
    width: 100%;
    padding: 0.8rem;
    border-radius: 8px;
    border: 0;
  }

  /* background: none;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 40px;
  height: 40px;
  top: 0px;
  left: 0px; */
  .ant-input-affix-wrapper-lg,  
  .ant-input-affix-wrapper-lg input {
    font-size: 70px;
  } 
